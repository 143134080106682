import SeleccioadorHotel from './views/SeleccionadorHotel';
import { useEffect, useState } from 'react';
import Router from './Router';

function App() {
  const [tieneHotel, setTieneHotel] = useState(false);
  const [refresh, setRefresh] = useState(false);


  useEffect(() => {
    const verificarHaSeleccionadoHotel = () => {
      if (localStorage.getItem('SH_idHotel') === null) {
        setTieneHotel(false);
      }else{
        setTieneHotel(true);
      }
    }


    verificarHaSeleccionadoHotel();
  }, [refresh]);

 const refrescar = () => {
    setRefresh(!refresh);
  }

  return (
    <div className="App flex flex-col min-h-screen">
      {
        // Si no es un subdominio, mostramos el SeleccionadorHotel
        tieneHotel  ? <Router />: <SeleccioadorHotel  refrescar={refrescar} />
        //<Router />
      }
    </div>
  );
}

export default App;