import React from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";
import { useAuthContext } from '../contexts/AuthContext';

export default function Header(props) {

const { logout } = useAuthContext();

const handleClick = (value) => {
  // Llama a la función pasada como prop desde el componente padre
  props.onItemClick(value);
}

  return (
    <Navbar >
      <NavbarBrand>
        <img src={`http://www.serveihabitacions.com/imatges/logo${localStorage.getItem('SH_idHotel')}.png`}
             alt="Logo" className="h-14" />
        </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link color="foreground" href="#" onClick={() => handleClick(2)}>
            Usuaris
          </Link>
        </NavbarItem>
        <NavbarItem >
          <Link href="#" color="foreground" onClick={() => handleClick(1)}>
            Habitacions
          </Link>
        </NavbarItem>
        <NavbarItem>
        <Link href="#" color="foreground" onClick={() => handleClick(3)}>
            Productes
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="#" onClick={() => handleClick(4)}>
            Configuració
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <Button as={Link} color="primary" href="#" variant="flat" onClick={logout}>
            Log out
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
