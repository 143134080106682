import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './contexts/AuthContext';
import Login from './views/Login';
import Dashboard from './views/Dashboard';


const Router = () => {
   const { isAuthenticated } = useAuthContext();
   return (
      <Routes>

         <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
         <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
      </Routes>
   );
}
export default Router;