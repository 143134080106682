import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Divider, Image, Input, Link, Button } from '@nextui-org/react'
import { EyeFilledIcon } from "../components/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../components/EyeSlashFilledIcons";
import SH from '../assets/Sh.png'
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
const Login = () => {

    const { login, isAuthenticated } = useAuthContext();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const [hotels, setHotels] = useState([])

    const toggleVisibility = () => setIsVisible(!isVisible);
    const navigate = useNavigate();

    useEffect(() => {

        let hotelID = localStorage.getItem('SH_idHotel')
        //enviamos el subdominio al servidor
        fetch(process.env.REACT_APP_API_BASE_URL + 'hotels/' + hotelID)
            .then(response => response.json())
            .then(data => {
                //si el subdominio no existe
                if (data.length === 0) {
                    //redirigimos a la pagina principal
                    window.location.href = 'https://www.serveihabitacions.com/'
                } else {

                    if (isAuthenticated) {
                        navigate('/')
                    }



                    setHotels(data[0])
                }
            })
            .catch(error => console.error(error))


    }, [isAuthenticated])






    const handleLogin = () => {
        if (username === '' || password === '') {
            alert('Introdueix el teu nom d\'usuari i contrasenya');
            return;
        }
        //enviamos los datos al servidor
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: username,
                password: password,
                hotelId: hotels.id
            })
        })
            .then(response => response.json())
            .then(data => {
                //si el usuario no existe
                if (data.error) {
                    alert(data.error)
                } else {
                    //si el usuario existe
                    //guardamos el token en el localstorage
                    login(data.token)
                    //redirigimos a la pagina del hotel

                }
            })
            .catch(error => console.error(error))



    }
    return (
        hotels && (
            <div className="h-screen flex justify-center items-center">
                <div className="max-w-[400px] min-w-[300px] flex justify-center items-center flex-col">
                    <Image src={`https://www.serveihabitacions.com/imatges/logo${hotels.id}.png`} width={150} height={100} className="rounded-none mb-4" />
                    <Card className="max-w-[400px]">
                        <CardHeader className="flex gap-3">
                            <Image
                                alt="nextui logo"
                                height={40}
                                radius="sm"
                                src={SH}
                                width={40}
                            />
                            <div className="flex flex-col">
                                <p className="text-md">{hotels.nom}</p>
                                <p className="text-xs text-default-500">Serveihabitacions.com</p>
                            </div>
                        </CardHeader>
                        <Divider />
                        <CardBody className="flex">
                            <Input className="mb-3" label="Nom d'usuari" placeholder="Introdueix el teu nom d'usuario" onChange={(e) => setUsername(e.target.value)} value={username} />
                            <Input
                                label="Contrasenya"
                                placeholder="La teva contrasenya"
                                onChange={(e) => setPassword(e.target.value)} value={password}
                                endContent={
                                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                        {isVisible ? (
                                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        ) : (
                                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                        )}
                                    </button>
                                }
                                type={isVisible ? "text" : "password"}
                                className="mb-6"
                            />
                            <Button color="primary" onClick={handleLogin}>Iniciar sessió</Button>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <Link
                                isExternal
                                showAnchorIcon
                                className="text-sm"
                            >
                                Tens problemes per inciar sessió?
                            </Link>
                        </CardFooter>
                    </Card>
                </div>
            </div>
        )
    )
}
export default Login;