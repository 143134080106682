import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Divider, Image, Link, Button } from '@nextui-org/react'
import { Select, SelectItem } from "@nextui-org/react";
import campana from '../assets/campana.png'
import logo from '../assets/logo.png'
import { Link as LinkNext } from '@nextui-org/react';


const SeleccioadorHotel = (props) => {
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [hotels, setHotels] = useState([]);

    const handleLogin = () => {
        if (selectedHotel === null) {
            alert('Selecciona un hotel');
            return;
        }
    
        // Busca el hotel seleccionado en la lista de hoteles
        const hotel = hotels.find(hotel => hotel.id === parseInt(selectedHotel.target.value));
    
        // Redirige a la página del hotel
        localStorage.setItem('SH_idHotel', JSON.stringify(hotel.id));

        props.refrescar();

       
    }

    

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL+'/conf/hotels')
            .then(response => response.json())
            .then(data => setHotels(data));
    }
        , []);

    const handleSelectChange = (value) => {
        setSelectedHotel(value);
    }

    return (
        <div className="min-h-screen flex justify-center items-center p-4">
        <div className="max-w-[400px] min-w-[300px] flex justify-center items-center flex-col">
            <Image src={logo} width={200} height={200} className="rounded-none mb-4" />
            <Card className="max-w-[400px] min-w-[300px] mx-auto">
                <CardHeader className="flex gap-3">
                    <Image
                        alt="nextui logo"
                        height={30}
                        radius="sm"
                        src={campana}
                        width={30}
                    />
                    <div className="flex flex-col">
                        <p className="text-md">Serveihabitacions.com</p>
                        <p className="text-xs text-default-500">Gestor d'hotels</p>
                    </div>
                </CardHeader>
                <Divider />
                <CardBody className="flex">
                    <Select
                        label="Selecciona el hotel"
                        placeholder="Selecciona el teu hotel"
                        className="w-full mb-4"
                        onChange={handleSelectChange}
                    >
                        {hotels.map((hotel) => (
                            <SelectItem key={hotel.id} value={hotel.id}>
                                {hotel.nom}
                            </SelectItem>
                        ))}
                    </Select>

                    <Button color="primary" onClick={handleLogin}>Accedir</Button>
                </CardBody>
                <Divider />
                <CardFooter>
                    <Link
                        isExternal
                        showAnchorIcon
                        className="text-sm"
                    >
                        Tens problemes per accedir?
                    </Link>
                </CardFooter>
            </Card>
            <p
                className="text-xs text-default-500 mt-4"
            >Powered by
                <LinkNext href="https://www.sabelic.com" target="_blank" rel="noopener" className="text-xs text-primary-500 ml-1 ">Sabelic </LinkNext> & <LinkNext href="https://www.azaxsolutions.com" target="_blank" rel="noopener" className="text-xs text-primary-500 ml-1">Azax Solutions </LinkNext>
            </p>
        </div>
    </div>
    )
}
export default SeleccioadorHotel;