import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHeader, TableRow, TableColumn } from "@nextui-org/react";
import { Input } from "@nextui-org/react";
import { Select, SelectItem } from "@nextui-org/react";
import { IoTimeOutline } from "react-icons/io5";
import { Checkbox } from "@nextui-org/react";

const ModalAdministrar = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [habitacions, setHabitacions] = useState([]);
    const [tipusHabitacions, setTipusHabitacions] = useState([]);
    const [editHabitacions, setEditHabitacions] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [llistatProductes, setLlistatProductes] = useState([]);
    const [llistatUsers, setLlistatUsers] = useState([]);
    const [llistatConfiguracio, setLlistatConfiguracio] = useState([]);


    // Si props.tipoAdmin es diferente de null, abre el modal
    useEffect(() => {
        if (props.tipoAdmin !== null) {
            onOpen();
            if (props.tipoAdmin === 1) {
                getHabitacions();
            }
            if (props.tipoAdmin === 2) {
                getUsers();
            }
            if (props.tipoAdmin === 3) {
                getProductes();
            }
            if (props.tipoAdmin === 4) {
                getConfiguracion();
            }
        
        } else {
            onClose();
        }
    }, [props.tipoAdmin, onOpen, onClose]);

    // Actualiza editHabitacions cuando habitacions cambia
    useEffect(() => {
        setEditHabitacions(habitacions);
    }, [habitacions]);

    const handleClose = () => {
        // Cierra el modal
        onClose();
        // Modifica tipoAdmin en el componente padre
        props.setTipoAdmin(null);
    }


    const getUsers = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + 'auth/user', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            },

        })
            .then(response => response.json())
            .then(data => {

                setLlistatUsers(data);
                setLoaded(true);
            })
            .catch(error => console.error(error))
    }

    const getConfiguracion = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/conf/configuracio', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                setLlistatConfiguracio(data);
                setLoaded(true);
            })
            .catch(error => console.error(error))
    }


    const getProductes = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/productes/all', {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                setLlistatProductes(data); // Asegúrate de que la respuesta tiene una propiedad 'productes'
                setLoaded(true); // Añade esta línea para manejar el estado de carga de los productos
            })
            .catch(error => console.error(error))
    }

    const handleAddProduct = () => {
        setLlistatProductes([...llistatProductes, { id: '', nom: '', action: 'add' }]);
    }
    const handleAddUser = () => {
        setLlistatUsers([...llistatUsers, { id: '', user: '', contrasenya: '', action: 'add' }]);
    }


    const handleInputChangeProduct = (index, field, value) => {
        const newProductes = [...llistatProductes];
        newProductes[index][field] = value;
        setLlistatProductes(newProductes);
    }
    const handleInputChangeUser = (index, field, value) => {
        const newUsers = [...llistatUsers];
        newUsers[index][field] = value;
        setLlistatUsers(newUsers);
    }


    const getHabitacions = () => {
        // Obtiene las habitaciones del hotel
        fetch(process.env.REACT_APP_API_BASE_URL + 'hotels/habitacions/all', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                setHabitacions(data.habitacions);
                setTipusHabitacions(data.tipusHabitacions);
                setLoaded(true);
            })
            .catch(error => console.error(error))
    }

    const handleAddHabitacion = () => {
        setEditHabitacions([...editHabitacions, { id: '', user: '', tipo: '' }]);
    }



    const handleInputChange = (index, field, value) => {
        const newHabitacions = [...editHabitacions];
        newHabitacions[index][field] = value;
        setEditHabitacions(newHabitacions);
    }

    const handleGuadar = () => {
        if (props.tipoAdmin === 1) {
            // Guarda las habitaciones del hotel
            fetch(process.env.REACT_APP_API_BASE_URL + 'hotels/habitacions/all', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('ServeihabToken')
                },
                body: JSON.stringify({ habitacions: editHabitacions })
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    setHabitacions(data.habitacions);
                    setTipusHabitacions(data.tipusHabitacions);
                    setLoaded(true);
                    props.handleRefresh();
                })
                .catch(error => console.error(error))
        }
        if (props.tipoAdmin === 2) {
            fetch(process.env.REACT_APP_API_BASE_URL + 'auth/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('ServeihabToken')
                },
                body: JSON.stringify({ users: llistatUsers })
            })
                .then(response => response.json())
                .then(data => {
                    props.handleRefresh();
                    //cerrar modal
                    handleClose();
                })
                .catch(error => console.error(error))


        }
        if (props.tipoAdmin === 3) {
            // Guarda las habitaciones del hotel

            fetch(process.env.REACT_APP_API_BASE_URL + 'hotels/productes/all', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('ServeihabToken')
                },
                body: JSON.stringify({ productes: llistatProductes })
            })
                .then(response => response.json())
                .then(data => {
                    props.handleRefresh();
                    //cerrar modal
                    handleClose();

                })
                .catch(error => console.error(error))

        }
        if (props.tipoAdmin === 4) {
            fetch(process.env.REACT_APP_API_BASE_URL + '/conf/configuracio', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('ServeihabToken')
                },
                body: JSON.stringify({ configuracio: llistatConfiguracio })
            })
                .then(response => response.json())
                .then(data => {
                    props.handleRefresh();
                    //cerrar modal
                    handleClose();
                })
                .catch(error => console.error(error))

        }

    }

    return (
        loaded && (

            <Modal isOpen={isOpen} onClose={handleClose} className="min-w-[30vw]">
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">
                        {props.tipoAdmin === 1 && 'Administrar habitacions'}
                        {props.tipoAdmin === 2 && 'Administrar usuaris'}
                        {props.tipoAdmin === 3 && 'Administrar productes'}
                        {props.tipoAdmin === 4 && 'Configuració'}

                    </ModalHeader>
                    <ModalBody>

                        {props.tipoAdmin === 3 && (
                            <>
                                <div style={{ maxHeight: '50vh', overflow: 'auto' }}>

                                    <Table aria-label="Productos">
                                        <TableHeader>
                                            <TableColumn>ID</TableColumn>
                                            <TableColumn>Nom</TableColumn>
                                            <TableColumn>Accions</TableColumn>
                                        </TableHeader>
                                        <TableBody>
                                            {llistatProductes.map((producte, index) => {
                                                if (producte.action !== 'del') {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{producte.id}</TableCell>
                                                            <TableCell>
                                                                <Input value={producte.nom} onChange={(e) => handleInputChangeProduct(index, 'nom', e.target.value)} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button color="danger" variant="light"
                                                                    onClick={() => {
                                                                        // Añade un atributo 'action' al objeto del producto
                                                                        setLlistatProductes(llistatProductes.map((product, i) => {
                                                                            if (i === index) {
                                                                                return { ...product, action: 'del' }
                                                                            }
                                                                            return product;
                                                                        }))
                                                                    }}
                                                                >Eliminar</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            })}
                                        </TableBody>
                                    </Table>

                                </div>
                                <Button color="primary" variant="light" onPress={handleAddProduct}>Afaegir producte</Button>
                            </>
                        )}


                        {props.tipoAdmin === 1 && (
                            <>
                                <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
                                    <Table aria-label="Habitaciones">
                                        <TableHeader>
                                            <TableColumn>ID</TableColumn>
                                            <TableColumn>Nom</TableColumn>
                                            <TableColumn>Tipus</TableColumn>
                                            <TableColumn>Accions</TableColumn>
                                        </TableHeader>
                                        <TableBody>
                                            {editHabitacions.map((habitacion, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{habitacion.id}</TableCell>
                                                    <TableCell>
                                                        <Input value={habitacion.nom} onChange={(e) => handleInputChange(index, 'nom', e.target.value)} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select value={habitacion.tipo}>
                                                            {tipusHabitacions.map((tipusHabitacion, index) => (
                                                                <SelectItem key={index} value={tipusHabitacion.id}>{tipusHabitacion.nombre}</SelectItem>
                                                            ))}
                                                        </Select>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Button color="danger" variant="light"

                                                        >Eliminar</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </div>
                                <Button color="primary" variant="light" onPress={handleAddHabitacion}>Añadir habitación</Button>
                            </>
                        )}
                        {props.tipoAdmin === 2 && (
                            <>
                                <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
                                    <Table aria-label="Habitaciones">
                                        <TableHeader>
                                            <TableColumn>ID</TableColumn>
                                            <TableColumn>User</TableColumn>
                                            <TableColumn>Password</TableColumn>
                                            <TableColumn>Accions</TableColumn>
                                        </TableHeader>
                                        <TableBody>
                                            {llistatUsers.map((user, index) => {
                                                if (user.action !== 'del') {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{user.id}</TableCell>
                                                            <TableCell>
                                                                <Input value={user.user} onChange={(e) => handleInputChangeUser(index, 'user', e.target.value)} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Input
                                                                    key={index}
                                                                    value={user.contrasenya}
                                                                    placeholder="******"
                                                                    onChange={(e) => {
                                                                        if (!user.contrasenya) {
                                                                            user.contrasenya = '';
                                                                        }
                                                                        handleInputChangeUser(index, 'contrasenya', e.target.value);
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button color="danger" variant="light"
                                                                    onClick={() => {
                                                                        // Añade un atributo 'action' al objeto del producto
                                                                        setLlistatUsers(llistatUsers.map((user, i) => {
                                                                            if (i === index) {
                                                                                return { ...user, action: 'del' }
                                                                            }
                                                                            return user;
                                                                        }))
                                                                    }}
                                                                >Eliminar</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <Button color="primary" variant="light" onPress={handleAddUser}>Afagir usuari</Button>
                            </>
                        )}
                        {props.tipoAdmin === 4 && (
                            <>
                                <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
                                    {


                                        <Table aria-label="Configuracio">
                                            <TableHeader>
                                                <TableColumn>Configuració</TableColumn>
                                                <TableColumn>Valor</TableColumn>
                                            </TableHeader>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Quantitat hores per mostrar alerta de neteja</TableCell>
                                                    <TableCell>
                                                        <Input
                                                            type="number"
                                                            labelPlacement="outside"
                                                            value={llistatConfiguracio[0].valor}
                                                            onChange={(e) => {
                                                                setLlistatConfiguracio(llistatConfiguracio.map((config, i) => {
                                                                    if (i === 0) {
                                                                        return { ...config, valor: Number(e.target.value) }
                                                                    }
                                                                    return config;
                                                                }))
                                                            }}

                                                            className="w-20 flex justify-center items-center"
                                                            startContent={
                                                                <div className="pointer-events-none flex items-center">
                                                                    <IoTimeOutline />
                                                                </div>
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Mostrar sempre avis de neteja si no hi ha huesped</TableCell>
                                                    <TableCell className="flex justify-center items-center">
                                                        <Checkbox 
                                                            isSelected={llistatConfiguracio[1].valor == 1}
                                                            onChange={(e) => {
                                                                setLlistatConfiguracio(llistatConfiguracio[1].valor == 1 ? llistatConfiguracio.map((config, i) => {
                                                                    if (i === 1) {
                                                                        return { ...config, valor: 0 }
                                                                    }
                                                                    return config;
                                                                }) : llistatConfiguracio.map((config, i) => {
                                                                    if (i === 1) {
                                                                        return { ...config, valor: 1 }
                                                                    }
                                                                    return config;
                                                                }))
                                                            }}
                                                        > 
                                                        
                                                        </Checkbox>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                    }
                                </div>
                            </>
                        )}

                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={handleClose}>
                            Tancar
                        </Button>
                        <Button color="primary" onPress={handleGuadar}>
                            Guardar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        )
    );
}
export default ModalAdministrar;