import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(null);

  const login = (token) => {
    localStorage.setItem('ServeihabToken', JSON.stringify(token));
    setAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('ServeihabToken');
    setAuthenticated(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('ServeihabToken');
    if (storedToken) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    // Mientras se verifica la autenticación, puedes devolver un componente de carga o null.
    return <div></div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
