import { Card, CardBody, CardFooter, CardHeader, Skeleton } from "@nextui-org/react";
import { Chip } from "@nextui-org/react";
import { CheckIcon } from "../components/CheckIcon";
import { NotificationIcon } from "../components/NotificationIcon";
import { FaPlus } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { FaSignInAlt } from "react-icons/fa";
import { useDisclosure } from "@nextui-org/react";
import { useState } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { Select, SelectItem } from "@nextui-org/react";
import { Button } from "@nextui-org/react";
import { GrUpdate } from "react-icons/gr";
import { FaTrashAlt } from "react-icons/fa";
import Header from "../components/Header";
import ModalAdministrar from "../components/ModalAdministrar";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "@nextui-org/react";
import { Tabs, Tab } from "@nextui-org/react";
import { useEffect } from "react";

const Dashboard = () => {
    const [selectedHabitacio, setSelectedHabitacio] = useState(0);
    const [llistatProductes, setLlistatProductes] = useState([]);
    const [value, setValue] = useState(new Set([]));
    const [habitacions, setHabitacions] = useState([]);
    const [tipusHabitacions, setTipusHabitacions] = useState([]);
    const [selectedType, setSelectedType] = useState(0);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onOpenChange: onOpenChange2 } = useDisclosure();
    const { isOpen: isOpen3, onOpen: onOpen3, onOpenChange: onOpenChange3 } = useDisclosure();
    const [updateInfo, setUpdateInfo] = useState(0);
    const [chekout, setCheckout] = useState(false);
    const [checkoutHabitacio, setCheckoutHabitacio] = useState(0);
    const [checkinHabitacio, setCheckinHabitacio] = useState(0);
    const [tipoAdmin, setTipoAdmin] = useState(null);
    const [personaCheckin, setPersonaCheckin] = useState(null);
    const [llistatConfiguracio, setLlistatConfiguracio] = useState([]);

    const afagirProducte = (habitacio, producte, nom) => {




        if (!window.confirm("Estas segur que vols afegir el producte a la habitacio " + nom + "?")) return;

        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/habitacions/producte/' + habitacio + '/' + producte, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                //haz que busque en habitacionSelected y le añada el producto a la lista de productos 

                setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
            })
            .catch(error => console.error(error))





    }


    const handleCheckout = (item) => {

        setCheckout(true);
        setCheckoutHabitacio(item);
        onOpenChange2();
    }
    const handleCheckin = (item) => {
           
        setCheckinHabitacio(item);
            onOpenChange3();
    }

    const getProductes = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/productes/all', {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                setLlistatProductes(data);
            })
            .catch(error => console.error(error))
    }

    const getHabitacions = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/habitacions/all', {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                setHabitacions(data.habitacions);
                setTipusHabitacions(data.tipusHabitacions);

                //si selectedHabitacio esta llena, busca en habitacions y selecciona la habitacion que tenga el mismo id que selectedHabitacio
                if (selectedHabitacio != 0) {
                    setSelectedHabitacio(data.habitacions.filter((item) => item.id == selectedHabitacio.id)[0]);
                }


            })
            .catch(error => console.error(error))
    }
    const setNateja = (id) => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/habitacions/nateja/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
            })
            .catch(error => console.error(error))

    }
    const getConfiguracion = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/conf/configuracio', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('ServeihabToken')
            }
        })
            .then(response => response.json())
            .then(data => {
                setLlistatConfiguracio(data);
            })
            .catch(error => console.error(error))
    }

        


    useEffect(() => {
        getProductes();
        getHabitacions();
        getConfiguracion();
    }, [updateInfo])


    const handleRefresh = () => {
        setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
    }

    return (


        <div className="min-h-screen flex justify-center items-center p-4">
            <div className="absolute top-0 left-0 w-full">
                <Header onItemClick={(value) => setTipoAdmin(value)} />
            </div>


            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-14 mb-14">
                {
                    habitacions && habitacions.length > 0 ?
                        habitacions.filter((item) => item.tipo == selectedType).length > 0 ?
                            habitacions.filter((item) => item.tipo == selectedType).map((item) => (


                                <Card shadow
                                    key={item.id}
                                    className={item.infoHabitacio[0].huesped ? "p-1 bg-gray-300" : "p-1 bg-gray-400"}
                                >
                                    <CardHeader className="cursor-pointer"
                                        onClick={() => setNateja(item.id)}
                                    >
                                        <Chip
                                            startContent={(llistatConfiguracio.find(config => config.tipo === 2)?.valor === 1 && !item.infoHabitacio[0].huesped) ? <NotificationIcon size={15} /> : (new Date() - new Date(item.horaNateja) > (llistatConfiguracio.find(config => config.tipo === 1)?.valor || 24) * 60 * 60 * 1000 ? <NotificationIcon size={15} /> : <CheckIcon size={15} />)}
                                            variant="faded"
                                            color={(llistatConfiguracio.find(config => config.tipo === 2)?.valor === 1 && !item.infoHabitacio[0].huesped) ? "danger" : (new Date() - new Date(item.horaNateja) > (llistatConfiguracio.find(config => config.tipo === 1)?.valor || 24) * 60 * 60 * 1000 ? "danger" : "success")}
                                            className="text-xs"
                                        >
                                            Neteja {new Date(item.horaNateja).getDate().toString().padStart(2, '0')}/{(new Date(item.horaNateja).getMonth() + 1).toString().padStart(2, '0')} - {new Date(item.horaNateja).getHours().toString().padStart(2, '0')}:{new Date(item.horaNateja).getMinutes().toString().padStart(2, '0')}
                                        </Chip>
                                    </CardHeader>
                                    <CardBody>
                                        <h1
                                            className="text-2xl font-bold text-center"
                                        >{item.nom}</h1>
                                    </CardBody>
                                    <CardFooter className="flex justify-between items-center gap-8">
                                        <p className="text-xs">{item.productos.length} Productes</p>
                                        <div className="flex justify-end">
                                            <button className="p-2" onClick={() => { item.infoHabitacio[0].huesped ? handleCheckout(item) : handleCheckin(item) }}>
                                                {item.infoHabitacio[0].huesped ? <FaSignOutAlt /> : <FaSignInAlt />}
                                            </button>
                                            <button className="bg-green-500 hover:bg-green-700 text-white  p-2 rounded-full"
                                                onClick={() => { onOpen(); setSelectedHabitacio(item); setUpdateInfo(!updateInfo) }}
                                            >
                                                <FaPlus />
                                            </button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            ))
                            :
                            <Card shadow
                                className="p-1 bg-gray-300 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                            >
                                <CardBody>
                                    <h1

                                    >No hi ha habitacions disponibles</h1>
                                </CardBody>
                            </Card>

                        :
                        <Card shadow
                            className="p-1 bg-gray-300 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        >
                            <CardBody>
                                <h1
                                    className="text-center"
                                >No hi ha habitacions disponibles</h1>
                            </CardBody>
                        </Card>
                }
            </div>


            {
                tipusHabitacions && tipusHabitacions.length > 0 &&
                <div className="fixed bottom-2 left-1/2 transform -translate-x-1/2 flex justify-center items-center gap-2 z-30">
                    <Tabs aria-label="Options"
                        onSelectionChange={(key) => {
                            setSelectedType(key)
                            setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
                        }
                        }

                    >
                        {
                            tipusHabitacions.map((type) => (
                                <Tab key={type.id} title={`${type.nombre}`} value={type.id}>
                                </Tab>
                            ))
                        }
                    </Tabs>
                    <Button
                        color="primary"
                        auto
                        onClick={() => {
                            setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
                        }}
                    >
                        <GrUpdate />
                    </Button>
                </div>
            }

            <Modal
                isOpen={isOpen2}
                onOpenChange={onOpenChange2}
                placement="bottom-center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="text-gray-500 text-medium font-normal">Checkout de la <span className="ml-1 text-default-800"> habitació {checkoutHabitacio.nom}</span></ModalHeader>
                            <ModalBody>
                                <div className="container">
                                <p className="text-gray-400 text-xs font-normal mb-2">Estas realitzant el checkout del client <span className="text-default-800">{checkoutHabitacio.infoHabitacio[0].huesped}</span></p>
                                    <p className="text-gray-400 text-xs font-normal mb-2">Llistat de productes</p>
                                    {
                                        //hazmeos que imprima los productos de la habitacion seleccionada

                                        checkoutHabitacio.productos && checkoutHabitacio.productos.length > 0 ?
                                            checkoutHabitacio.productos.map((producte) => (

                                                <p key={producte.id} className="text-small ">-
                                                    {llistatProductes.filter((item) => item.id == producte.idProd)[0].nom}
                                                    <span className="text-xs ml-1 text-gray-400">
                                                        {new Date(producte.hora).getDate().toString().padStart(2, '0')}/{(new Date(producte.hora).getMonth() + 1).toString().padStart(2, '0')} - {new Date(producte.hora).getHours().toString().padStart(2, '0')}:{new Date(producte.hora).getMinutes().toString().padStart(2, '0')}
                                                    </span>

                                                </p>
                                            ))
                                            :
                                            <p className="text-xs">No hi ha productes</p>

                                    }


                                    <Button className="mt-6" color="primary" auto onClick={() => {
                                        if (!window.confirm("Estas segur que vols fer el checkout de la habitacio " + checkoutHabitacio.nom + "?")) return;
                                        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/habitacions/checkout/' + checkoutHabitacio.id, {
                                            method: 'PUT',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'token': localStorage.getItem('ServeihabToken')
                                            }
                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
                                                setCheckout(false);
                                                setCheckoutHabitacio(0);
                                                onOpenChange2();



                                            })
                                            .catch(error => console.error(error))

                                    }}>
                                        Checkout
                                    </Button>
                                </div>
                            </ModalBody>

                            <ModalFooter>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isOpen3}
                onOpenChange={onOpenChange3}
                placement="bottom-center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="text-gray-500 text-medium font-normal">Checkin de la <span className="ml-1 text-default-800"> habitació {checkinHabitacio.nom}</span></ModalHeader>
                            <ModalBody>
                                <div className="container">
                                <p className="text-gray-400 text-xs font-normal mb-2">Ingresa el nom o DNI del client</p>
                                    <input type="text" className="w-full p-2 border border-gray-300 rounded-md" onChange={(e) => setPersonaCheckin(e.target.value)} />
                                    
                                   



                                    <Button className="mt-6" color="primary" auto onClick={() => {
                                        if (!window.confirm("Estas segur que vols fer el checkin de la habitacio " + checkinHabitacio.nom + "?")) return;
                                        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/habitacions/checkin/' + checkinHabitacio.id, {
                                            
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'token': localStorage.getItem('ServeihabToken')
                                            },
                                            body: JSON.stringify({
                                                persona: personaCheckin
                                            })


                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
                                                setCheckinHabitacio(0);
                                                onClose();

                                            })
                                            .catch(error => console.error(error))

                                    }}>
                                        Checkin
                                    </Button>
                                </div>
                            </ModalBody>

                            <ModalFooter>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="bottom-center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="text-gray-500 text-medium font-normal">Afegir producte a la <span className="ml-1 text-default-800"> habitació {selectedHabitacio.nom}</span></ModalHeader>
                            <ModalBody>

                                <div className="">
                                    <p className="text-gray-400 text-xs font-normal">Llistat de productes</p>
                                    <div className="flex justify-between items-center gap-2">
                                        <Select
                                            placeholder="Selecciona el producte"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            className="w-full"
                                        >
                                            {
                                                llistatProductes.map((producte) => (
                                                    <SelectItem key={producte.id} value={producte.id}>{producte.nom}</SelectItem>
                                                ))
                                            }
                                        </Select>
                                        <Button className="" color="primary" auto onClick={() => afagirProducte(selectedHabitacio.id, value, selectedHabitacio.nom)}>
                                            Afegir
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex py-2 px-1 justify-between">
                                    <Table aria-label="Example static collection table">
                                        <TableHeader>
                                            <TableColumn>PRODUCTE</TableColumn>
                                            <TableColumn>DATA</TableColumn>
                                            <TableColumn>ELIMINAR</TableColumn>
                                        </TableHeader>
                                        <TableBody>
                                            {
                                                selectedHabitacio.productos && selectedHabitacio.productos.length > 0 ?
                                                    selectedHabitacio.productos.map((producte) => (
                                                        <TableRow key={producte.id}>
                                                            <TableCell>{
                                                                llistatProductes.filter((item) => item.id == producte.idProd)[0].nom
                                                            }</TableCell>
                                                            <TableCell>{new Date(producte.hora).getDate().toString().padStart(2, '0')}/{(new Date(producte.hora).getMonth() + 1).toString().padStart(2, '0')} - {new Date(producte.hora).getHours().toString().padStart(2, '0')}:{new Date(producte.hora).getMinutes().toString().padStart(2, '0')}</TableCell>
                                                            <TableCell className="flex justify-center items-center gap-2">
                                                                <button className="bg-red-500 hover:bg-red-700 text-white  p-2 rounded-full"
                                                                    onClick={() => {
                                                                        if (!window.confirm("Estas segur que vols eliminar el producte?")) return;
                                                                        fetch(process.env.REACT_APP_API_BASE_URL + '/hotels/habitacions/producte/' + selectedHabitacio.id + '/' + producte.id, {
                                                                            method: 'DELETE',
                                                                            headers: {
                                                                                'Content-Type': 'application/json',
                                                                                'token': localStorage.getItem('ServeihabToken')
                                                                            }
                                                                        })
                                                                            .then(response => response.json())
                                                                            .then(data => {
                                                                                setUpdateInfo(Math.floor(Math.random() * 1000) + 1);
                                                                            })
                                                                            .catch(error => console.error(error))
                                                                    }}
                                                                >
                                                                    <FaTrashAlt />
                                                                </button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    :
                                                    <TableRow>
                                                        <TableCell>No hi ha productes</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            </ModalBody>
                            <ModalFooter>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <ModalAdministrar tipoAdmin={tipoAdmin} setTipoAdmin={setTipoAdmin} handleRefresh={handleRefresh} />
        </div>

    );
}
export default Dashboard;